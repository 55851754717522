<app-header-language></app-header-language>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="assets/logos/MYXGL.png" style="height: 50px; width: 250px" alt=""
  /></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarText"
    aria-controls="navbarText"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse"
    style="padding-right: 10%; font-size: medium"
    id="navbarText"
  >
    <ul class="navbar-nav mr-auto ml-auto">
      <li class="nav-item">
        <label>
          <a class="nav-link" href="https://xgl-logistics.com/xgl/">{{
            "XGL" | translate
          }}</a>
        </label>
      </li>
      <li class="dropdown">
        <a
          href="#"
          class="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="nav-label">{{ "Oficinas" | translate }}</span>
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
          <li class="label-dropdown-menu">
            <label
              ><a href="https://xgl-logistics.com/oficinas-spain/">{{
                "XGL Spain" | translate
              }}</a></label
            >
          </li>
          <li class="label-dropdown-menu">
            <label
              ><a href="https://xgl-logistics.com/oficinas-italy/">{{
                "XGL Italy" | translate
              }}</a></label
            >
          </li>
          <li class="label-dropdown-menu">
            <label
              ><a href="https://xgl-logistics.com/oficinas-mexico/">{{
                "XGL Mexico" | translate
              }}</a></label
            >
          </li>
          <li class="label-dropdown-menu">
            <label
              ><a href="https://xgl-logistics.com/oficinas-uae/">{{
                "XGL UAE" | translate
              }}</a></label
            >
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <label>
          <a class="nav-link" href="https://xgl-logistics.com/blog/">{{
            "Blog" | translate
          }}</a>
        </label>
      </li>
      <li class="nav-item">
        <label>
          <a class="nav-link" href="https://xgl-logistics.com/contacto/">{{
            "Contacto" | translate
          }}</a>
        </label>
      </li>
      <li class="nav-item">
        <label>
          <a
            class="nav-link"
            href="https://xgl-logistics.com/solicita-cotizacion/"
            >{{ "SolicitaCotizacion" | translate }}</a
          >
        </label>
      </li>
    </ul>
    <app-header-fijo></app-header-fijo>
  </div>
</nav>
