import { AbstractControl, ValidatorFn } from "@angular/forms";

export function cifValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let correcto = false;
  
      let letrasCodigo: string[] = [ 'J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I' ];
      let cif: string = control.value;
      let digitoControl = cif.substr(8,1);
      let letra: string = cif.substr(0,1).toUpperCase();
  
      let digitosControl: string = cif.substring(1, 8)
      let primerDigitoPar: number = Number(digitosControl.substr(1,1));
      let segundoDigitoPar: number = Number(digitosControl.substr(3,1));
      let tercerDigitoPar: number = Number(digitosControl.substr(5,1));
      let sumaCifrasPares: number = primerDigitoPar + segundoDigitoPar + tercerDigitoPar;
      let primerDigitoImpar: number = Number(digitosControl.substr(0,1));
      let segundoDigitoImpar: number = Number(digitosControl.substr(2,1));
      let tercerDigitoImpar: number = Number(digitosControl.substr(4,1));
      let cuartoDigitoImpar: number = Number(digitosControl.substr(6,1));
      let sumaMult1 = primerDigitoImpar*2;
      let sumaMult2 = segundoDigitoImpar*2;
      let sumaMult3 = tercerDigitoImpar*2;
      let sumaMult4 = cuartoDigitoImpar*2;
      let sumaCifrasImpares: number = (Number(sumaMult1.toString().substr(0,1)) + Number(sumaMult1.toString().substr(1,1))) +
                                      (Number(sumaMult2.toString().substr(0,1)) + Number(sumaMult2.toString().substr(1,1))) +
                                      (Number(sumaMult3.toString().substr(0,1)) + Number(sumaMult3.toString().substr(1,1))) +
                                      (Number(sumaMult4.toString().substr(0,1)) + Number(sumaMult4.toString().substr(1,1)));
      let sumaTotal = sumaCifrasPares + sumaCifrasImpares;
      let unidad = Number(sumaTotal.toString().substr(1,1));
      let numero = 10 - unidad;
      switch(letra)
      {
        // Sólo numeros
        case 'A': correcto = true; break;
        case 'B': correcto = true; break;
        case 'E': correcto = true; break;
        case 'H':
          //Se hace algo
          correcto = digitoControl == numero.toString();
          break;
        case 'K': correcto = true; break;
        case 'P': correcto = true; break;
        case 'Q': correcto = true; break;
        case 'S':
          //Se hace algo
          correcto = digitoControl == letrasCodigo[numero];
          break;
        default:
          correcto = (digitoControl == numero.toString()) ||
                      (digitoControl == letrasCodigo[numero]);
          break;
      }
      if(!correcto)
            return {'cifValidator': true}
      return null;
      
    //   let letrasNif: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
    //     let nif: string = control.value;
    //     if(nif == null)
    //         return {'cifValidator': true}
    //     let letra: string = nif.substr(8, 1).toUpperCase();
    //     let numNif: number = Number(nif.substr(0, 8));
    //     let resto = numNif % 23;
    //     if(letrasNif[resto] != letra)
    //         return {'cifValidator': true}
    //     return null;
      
    };
}