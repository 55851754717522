// #region --------- GLOBALES QUE SUELEN ESTAR EN TODOS LOS MODULES -------------
// #region Librerias que se utilizan casi siempre
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
// #endregion Librerias que se utilizan casi siempre
// #region Validadores
import { nifValidator } from './validators/nif-validator.validator';
import { arrayValidator } from './validators/array-validator.validator';
import { cifValidator } from './validators/cif-validator.validator';
// #endregion Validadores
// #endregion --------- GLOBALES QUE SUELEN ESTAR EN TODOS LOS MODULES -------------
// #region --------- EXCLUSIVOS APP.MODULE.TS -------------
// #region Componentes
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { LoginService } from '../app/services/login.service';
import { LoginConductorComponent } from './components/login/login-conductor/login-conductor.component';
import { OlvidoPasswordComponent } from './components/login/olvido-password/olvido-password.component';
import { RegistroUsuarioComponent } from './components/login/registro-usuario/registro-usuario.component';
import { TerminosYCondicionesComponent } from './components/terminos-y-condiciones/terminos-y-condiciones.component';
// #endregion Componentes
// #region Environment
import { environment } from './../environments/environment';
// #endregion Environment
// #region Librerías
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';
// #endregion Librerías
// #region ModulesExternos
import { SharedModule } from './shared/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { WaitingComponent } from './components/waiting/waiting.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { ComponentesAuxiliaresModule } from './components/componentesAuxiliares/componentes-auxiliares/componentes-auxiliares.module';
import { CommonModule } from '@angular/common';
// #region ModulesExternos
// #endregion --------- EXCLUSIVOS APP.MODULE.TS -------------



// SI NO FALLA NADA, BORRAR 
// import { TrackingDetailsComponent } from './components/users/customers/tracking-details/tracking-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    LoginConductorComponent,
    TerminosYCondicionesComponent,
    OlvidoPasswordComponent,
    RegistroUsuarioComponent,
    WaitingComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgxPaginationModule,
    NgxDocViewerModule,
    ModalModule.forRoot(),
    NgxPicaModule,
    ChartsModule,
    NgxGalleryModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressAnimation: 'increasing',
      progressBar: true,
      preventDuplicates: true
    }),
    RouterModule,
    PdfJsViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    AngularMaterialModule,
    ComponentesAuxiliaresModule,
  ],
  providers: [
    LoginService,
    NgxImageCompressService,
    { provide: NG_VALIDATORS, useExisting: nifValidator, multi: true },
    { provide: NG_VALIDATORS, useExisting: arrayValidator, multi: true },
    { provide: NG_VALIDATORS, useExisting: cifValidator, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [
    WaitingComponent
  ]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.traduccion);
}
