import { AbstractControl, ValidatorFn } from "@angular/forms";

export function arrayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let array = control.value;
        if(array == null || array.length <= 0){
            return {'arrayValidator': true}
        }
        return null;
    };
}