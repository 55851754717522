import { Injectable, OnInit, SimpleChange } from '@angular/core';
import { Shipment } from '../models/shipment.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { EnvioAereo } from '../models/envio-aereo.model';
import { Observable, Subject } from 'rxjs';
import { Incoterms } from '../models/incoterms.model';
import { Aeropuerto } from '../models/aeropuerto.model';
import { Puerto } from '../models/puerto.model';
import { Naviera } from '../models/naviera.model';
import { Modoenvio } from '../models/modoenvio.model';
import { Paises } from '../models/paises.model';
import { Localidades } from '../models/localidades.model';
import { Direccion } from '../models/direccion.model';
import { Contenedor } from '../models/contenedor.model';
import { map } from 'rxjs/operators';
import { TipoEnvio } from '../models/tipo-envio.model';
import { TipoDivisa } from '../models/tipo-divisa.model';
import { Plantilla } from '../models/plantilla.modal';
import { Tracking } from '../models/tracking.model';
import { Entity } from '../models/entity.model';
import { LogModel } from '../models/log-model.model';
import { ShipmentExport } from '../models/shipment-export.model';
import { Embalaje } from '../models/embalaje.model';
import { LogService } from './log.service';
import { Resumen } from '../models/resumen.model';
import { VerificacionTracking } from '../models/verificacionTracking.model';
import { LineasIngreso } from '../models/lineasIngreso.model';

@Injectable({
  providedIn: 'root'
})

export class ShipmentService {

  tipoEnvio: TipoEnvio[];
  formDataAereo: Shipment;
  formDataMaritimo: Shipment;
  formDataTerrestre: Shipment;
  visor: boolean;
  editar: boolean;
  duplicar: boolean;
  isCotizacion: boolean;
  idEnvio: number;
  idModoEnvio: number;
  idAereo: number;
  idMaritimo: number;
  idTerrestre: number;
  public envio: Shipment;
  partida: string;
  expedicion: string;
  cotizacion: string;
  public posicionModoEnvioFCL: number = 0;
  public posicionModoEnvioLCL: number = 1;

  list: EnvioAereo[] = [];

  readonly apiUrlService = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  ip: string = "";
  localizacion: string = "";
  listadoAereo$: Subject<Shipment[]> = new Subject();
  listadoMaritimo$: Subject<Shipment[]> = new Subject();
  listadoTerrestre$: Subject<Shipment[]> = new Subject();

  constructor(private http: HttpClient, private logService: LogService) {
    this.idAereo = 1;
    this.idMaritimo = 1;
    this.idTerrestre = 1;
    this.idEnvio = -1;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.localizacion = " latitude: " + latitude + " longitude: " + longitude;
      }, (error) => this.localizacion = 'Error al obtener la ubicación: ' + error);
    } else
      this.localizacion = 'Geolocalización no es soportada por este navegador.';
  }

  getListadoAereo$(): Observable<Shipment[]> {
    return this.listadoAereo$.asObservable();
  }
  getListadoMaritimo$(): Observable<Shipment[]> {
    return this.listadoMaritimo$.asObservable();
  }
  getListadoTerrestre$(): Observable<Shipment[]> {
    return this.listadoTerrestre$.asObservable();
  }

  getToken() {
    return localStorage.getItem("userToken");
  }

  getIdShipment(partidaExpedicion: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "cfg_envios/partida/" + partidaExpedicion;
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log obtener la entidad del usuario que ha iniciado sesión.",
      "Get_entidad_Web_usuario", "web_usuarios", url_api);
    return this.http.get<number>(url_api, { headers: headersToken });
  }

  getLineasIngreso(partida: string, expedicion: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    var partidaExpedicion;
    if (partida)
      partidaExpedicion = partida;
    else
      partidaExpedicion = encodeURIComponent(expedicion);
    const url_api = this.apiUrlService + "cfg_envios/partida/" + partidaExpedicion + "/ingresos";
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener todas las líneas de ingreso de la partida/expedición " + partidaExpedicion + ".",
      "Get_LineasIngresoPartidaOExpedicion", "cfg_envios", url_api);
    return this.http.get<LineasIngreso[]>(url_api, { headers: headersToken });
  }

  getShipmentByPartidaExpedicion(partidaExpedicion: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "cfg_envios/partidaExpedicion/" + partidaExpedicion;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el envío por partida o expedición",
      "Get_envioByPartidaExpedicion", "cfg_envios", url_api);
    return this.http.get<Shipment>(url_api, { headers: headersToken });
  }

  getEntidadUsuario(): Observable<number> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "web_usuarios/entidad";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log obtener la entidad del usuario que ha iniciado sesión.",
      "Get_entidad_Web_usuario", "web_usuarios", url_api);
    return this.http.get<number>(url_api, { headers: headersToken });
  }

  getTiposEnvio(area: number): Observable<TipoEnvio[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_tipoenvio/area/" + area;
    this.logService.postLogCompleto("GET", "cfg_tipoenvioController.cs", "Log obtener todos los tipos envio.",
      "Get_cfg_tipoenvioPorIdAreaAsync", "cfg_tipoenvio", url_api);
    return this.http.get<TipoEnvio[]>(url_api, { headers: headersToken });
  }

  // TO-DO: Repetición en mercancia.service.ts, eliminar el que más te guste
  getTipoContenedor(): Observable<Contenedor[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_tipocontenedor";
    this.logService.postLogCompleto("GET", "cfg_tipocontenedorController.cs", "Log obtener todos los tipo de contenedor.",
      "Get_cfg_tipocontenedor", "cfg_tipocontenedor", url_api);
    return this.http.get<Contenedor[]>(url_api, { headers: headersToken });
  }

  getModoEnvioByArea(area: number): Observable<Modoenvio[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_modoenvio/area/" + area;
    this.logService.postLogCompleto("GET", "cfg_modoenvioController.cs", "Log obtener todos los modo de envio del area " + area + ".",
      "Get_cfg_modoenvio", "cfg_modoenvio", url_api);
    return this.http.get<Modoenvio[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita las entidades que pueden ser shippers
  getShipper(ex: boolean): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_entidad/shipper/" + ex;
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todos shipper de la entidad que ha iniciado sesión.",
      "Get_em_entidadShipper", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  getShipperTerrestre(): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_entidad/shipper";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todos shipper de la entidad que ha iniciado sesión.",
      "Get_em_entidadShipper", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  getEnviosConFiltro(json, idArea, log: LogModel): Observable<Shipment[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios/filtros/" + idArea;
    return this.http.post<Shipment[]>(url_api, JSON.stringify({ "Log": log, "Body": JSON.stringify(json).toString() }), { headers: headersToken.append("Content-Type", "application/json") });
  }

  //Peticion que solicita las entidades que pueden ser consignees
  getConsignee(ex: boolean): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "em_entidad/consignee/" + ex;
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todos consignee de la entidad que ha iniciado sesión.",
      "Get_em_entidadConsignee", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  getEntidadesAddressBook(): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "myxgl_addressbook/addressbook";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todlas entidades del addressbook de la entidad que ha iniciado sesión.",
      "Get_em_entidadByAddressBook", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  getConsigneeTerrestre(): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "em_entidad/consignee";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todos consignee de la entidad que ha iniciado sesión.",
      "Get_em_entidadConsignee", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita las entidades que pueden ser notify
  getNotify(IdConsignee: number): Observable<Entity[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "em_entidad/notify/consignee/" + IdConsignee;
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener todos notify del consignee " + IdConsignee + ".",
      "Get_em_entidadNotify", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita la lista de incoterms
  getIncoterms(idArea): Observable<Incoterms[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_incoterms/" + idArea;
    /*this.logService.postLogCompleto("GET", "cfg_incotermsController.cs", "Log obtener todos los incoterms.",
     "Get_cfg_incoterms", "cfg_incoterms", url_api);*/
    return this.http.get<Incoterms[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita la lista de aeropuertos
  getAeropuertos(): Observable<Aeropuerto[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "ma_aeropuerto";
    this.logService.postLogCompleto("GET", "ma_aeropuertoController.cs", "Log obtener todos los aeropuertos.",
      "Get_ma_aeropuerto", "ma_aeropuerto", url_api);
    return this.http.get<Aeropuerto[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita la lista de puertos
  getPuertos(): Observable<Puerto[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "ma_puertos";
    this.logService.postLogCompleto("GET", "ma_puertosController.cs", "Log obtener todos los aeropuertos.",
      "Getma_puertos", "ma_puertos", url_api);
    return this.http.get<Puerto[]>(url_api, { headers: headersToken });
  }

  //Peticion que solicita la lista de Aerolineas
  /*getAerolineas(): Observable<Aerolinea[]> {
    
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_compañiaaerea";
    this.logService.postLogCompleto("GET", "cfg_compañiaaereaController.cs", "Log obtener todos los aeropuertos.",
     "Getcfg_puertos", "cfg_compañiaaerea", url_api);
    return this.http.get<Aerolinea[]>(url_api, {headers: headersToken});
  }*/

  putEnvioAereo(Envio: Shipment, idEnvio: number, logModel: LogModel) {

    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios/" + idEnvio;
    return this.http.put(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(Envio).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  putEnvioTerrestre(Envio: Shipment, idEnvio: number, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios/" + idEnvio;
    return this.http.put(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(Envio).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  putEnvioMaritimo(Envio: Shipment, idEnvio: number, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios/" + idEnvio;
    return this.http.put(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(Envio).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  //Peticion 
  postEnvioAereo(Envio: Shipment, logModel: LogModel, languaje: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken(),
      "Access-Control-Allow-Origin": "*"
    })

    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios";
    return this.http.post(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(Envio).toString(),
      "Language": languaje
    }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  postEnvioTerrestre(EnvioTerrestre: Shipment, logModel: LogModel, language: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios";
    return this.http.post(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(EnvioTerrestre).toString(),
      "Language": language
    }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  postEnvioMaritimo(EnvioMaritimo: Shipment, logModel: LogModel, language: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios";
    return this.http.post(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(EnvioMaritimo).toString(),
      "Language": language
    }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  public setVisor(visor: boolean) {
    this.visor = visor;
  }

  public getVisor() {
    return this.visor;
  }

  public setEditar(editar: boolean) {
    this.editar = editar;
  }

  public getEditar() {
    return this.editar;
  }

  public setDuplicar(duplicar: boolean) {
    this.duplicar = duplicar;
  }

  public getDuplicar() {
    return this.duplicar;
  }

  public getCotizacionBoolean() {
    return this.isCotizacion;
  }

  public setCotizacionBoolean(isCotizacion: boolean) {
    this.isCotizacion = isCotizacion;
  }

  //Peticion en el que obtenemos un listado de navieras
  getNavieras(): Observable<Naviera[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_navieras";
    this.logService.postLogCompleto("GET", "cfg_navierasController.cs", "Log obtener todas las navieras.",
      "Get_cfg_navieras", "cfg_navieras", url_api);
    return this.http.get<Naviera[]>(url_api, { headers: headersToken });
  }

  //Peticion en el que obtenemos un listado de envios
  getEnvios(idArea: string, idModoEnvio: string): Observable<Shipment[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "cfg_envios/consulta/" + idArea + "/" + idModoEnvio;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener todos los envios del cliente con el área " + idArea + ".",
      "Get_cfg_envios", "cfg_envios", url_api);
    return this.http.get<Shipment[]>(url_api, { headers: headersToken })
  }


  getEnvios2(idArea: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "cfg_envios/consulta/" + idArea;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener todos los envios del cliente con el área " + idArea + ".",
      "Get_cfg_envios", "cfg_envios", url_api);
    this.http.get<Shipment[]>(url_api, { headers: headersToken }).subscribe(listadoEnvios => {
      if (idArea == "1")
        this.listadoAereo$.next(listadoEnvios);
      else if (idArea == "2")
        this.listadoMaritimo$.next(listadoEnvios);
      else if (idArea == "3")
        this.listadoTerrestre$.next(listadoEnvios);

    });
  }

  //Peticion que inserta un envio en la bbdd
  setEnvio(envio: Shipment, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_envios";
    return this.http.post(url_api, envio, { headers: headersToken.append('Content-Type', "application/json") });
  }

  setIdEnvio(id: number) {
    this.idEnvio = id;
  }

  setPartida(partida: string) {
    this.partida = partida;
  }

  setExpedicion(expedicion: string) {
    this.expedicion = expedicion;
  }

  setCotizacion(cotizacion: string) {
    this.cotizacion = cotizacion;
  }

  getIdEnvio(): number {
    return this.idEnvio;
  }

  getPartida(): string {
    return this.partida;
  }

  getCotizacion(): string {
    return this.cotizacion;
  }

  getExpedicion(): string {
    return this.expedicion;
  }

  //Peticion que obtiene un envio de la bbdd
  getEnvio(id: number): Observable<any> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_envios/" + id;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener la información de un envio " + id + ".",
      "Get_cfg_enviosPorId", "cfg_envios", url_api);
    return this.http.get<any>(url_api, { headers: headersToken });
  }

  //Peticion en el que obtenemos el listado de direcciones
  getDirecciones(): Observable<Direccion[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_direcciones";
    this.logService.postLogCompleto("GET", "em_direccionesController.cs", "Log obtener todas las direcciones.",
      "Get_em_direcciones", "em_direcciones", url_api);
    return this.http.get<Direccion[]>(url_api, { headers: headersToken });
  }

  getDireccionesShipper(): Observable<Direccion[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_direcciones/shipper";
    this.logService.postLogCompleto("GET", "em_direccionesController.cs", "Log obtener todas las direcciones del shipper del cliente.",
      "Get_em_direccionesShipper", "em_direcciones", url_api);
    return this.http.get<Direccion[]>(url_api, { headers: headersToken });
  }

  getDireccionesConsignee(): Observable<Direccion[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "em_direcciones/consignee";
    this.logService.postLogCompleto("GET", "em_direccionesController.cs", "Log obtener todas las direcciones del shipper del cliente.",
      "Get_em_direccionesConsignee", "em_direcciones", url_api);
    return this.http.get<Direccion[]>(url_api, { headers: headersToken });
  }

  //Peticion en el que obtenemos el listado de paises
  getPaises(): Observable<Paises[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "ma_paises";
    this.logService.postLogCompleto("GET", "ma_paisesController.cs", "Log obtener todos los paises.",
      "Get_ma_paises", "ma_paises", url_api);
    return this.http.get<Paises[]>(url_api, { headers: headersToken })
  }

  //Peticion en el que obtenemos el listado de localidades
  getLocalidades(): Observable<Localidades[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "ma_localidades";
    this.logService.postLogCompleto("GET", "ma_localidadesController.cs", "Log obtener todas las localidades.",
      "Get_ma_localidades", "ma_localidades", url_api);
    return this.http.get<Localidades[]>(url_api, { headers: headersToken });
  }

  getTiposDivisa(): Observable<TipoDivisa[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_tipodivisa";
    this.logService.postLogCompleto("GET", "cfg_tipodivisaController.cs", "Log obtener todas las divisas.",
      "Get_cfg_tipodivisas", "cfg_tipodivisa", url_api);
    return this.http.get<TipoDivisa[]>(url_api, { headers: headersToken });
  }

  getPlantillas(idArea: number): Observable<Plantilla[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "cfg_plantillas/area/" + idArea;
    this.logService.postLogCompleto("GET", "cfg_plantillasController.cs", "Log obtener todas las plantillas del cliente que ha iniciado sesión por el área " + idArea + ".",
      "Getcfg_plantillas", "cfg_plantillas", url_api);
    return this.http.get<Plantilla[]>(url_api, { headers: headersToken })
  }

  getPlantilla(id: number): Observable<Shipment> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_plantillas/" + id;
    this.logService.postLogCompleto("GET", "cfg_plantillasController.cs", "Log obtener todas la plantilla número " + id + ".",
      "Getcfg_plantillasPorId", "cfg_plantillas", url_api);
    return this.http.get<any>(url_api, { headers: headersToken });
  }

  postPlantilla(plantilla: Shipment, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "cfg_plantillas";
    return this.http.post(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(plantilla).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  deletePlantilla(id: number) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + 'cfg_plantillas/' + id;
    this.logService.postLogCompleto("DELETE", "cfg_plantillasController.cs", "Log borrar la plantilla número " + id + ".",
      "Deletecfg_plantillas", "cfg_plantillas", url_api);
    return this.http.delete(url_api, { headers: headersToken })
  }

  getTracking(): Observable<Tracking> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    let url_api = this.apiUrlService + "cfg_envios";
    if (this.envio.Partida != null) { // Es fusion (ver partida)
      url_api = url_api + "/tracking/partida/" + this.envio.Partida;
      this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de la partida.",
        "Get_cfg_envios_trackingPorIdPartida", "cfg_envios", url_api);
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else if (this.envio.Expedicion != null) { // Es fusion (ver expedicion)
      var expedicionEncode = encodeURIComponent(this.envio.Expedicion);
      url_api = url_api + "/tracking/expedicion/" + expedicionEncode;
      this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de la expedición.",
        "Get_cfg_envios_trackingPorExpedicion", "cfg_envios", url_api);
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else if (this.envio.IdEnvio != 0) { // Es nuestro (New fusion)
      url_api = url_api + "/" + this.envio.IdEnvio + "/tracking";
      this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking del envio.",
        "Get_cfg_envios_trackingPorIdEnvio", "cfg_envios", url_api);
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else {

    }
  }

  getTrackingShare(codigoTracking: string): Observable<Tracking> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_envios";
    if (this.envio.Partida != null) { // Es fusion (ver partida)
      url_api = url_api + "/tracking/partida/" + this.envio.Partida + "/compartir/" + codigoTracking;
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else if (this.envio.Expedicion != null) { // Es fusion (ver expedicion)
      var expedicionEncode = encodeURIComponent(this.envio.Expedicion);
      url_api = url_api + "/tracking/expedicion/" + expedicionEncode + "/compartir/" + codigoTracking;
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else {

    }
  }

  getTrackingPartidaExpedicion(partida: string, expedicion: string): Observable<Tracking> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_envios";
    if (partida != null && partida != "") { // Es fusion (ver partida)
      url_api = url_api + partida + "/tracking/share";
      this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de la partida.",
        "Get_cfg_envios_trackingPorIdPartida", "cfg_envios", url_api);
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    } else if (expedicion != null && expedicion != "") { // Es fusion (ver expedicion)
      var expedicionEncode = encodeURIComponent(this.envio.Expedicion);
      url_api = url_api + "/tracking/expedicion/" + expedicionEncode;
      this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de la expedición.",
        "Get_cfg_envios_trackingPorExpedicion", "cfg_envios", url_api);
      return this.http.get<Tracking>(url_api, { headers: headersToken })
    }
  }

  getResumen(referencia: string) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    if (referencia.includes("/"))
      referencia = encodeURIComponent(referencia);
    let url_api = this.apiUrlService + "cfg_envios/resumen/" + referencia;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log se ha devuelto correctamente el resumen del envio con el identificador " + referencia,
      "Get_cfg_enviosPartidaOExpedicion", "cfg_envios", url_api);
    return this.http.get<Resumen>(url_api, { headers: headersToken });
  }

  getListaEnvioExcel(IdArea: number, idModoEnvio: number): Observable<ShipmentExport[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_envios/consulta/envios/exportToExcel/" + IdArea + "/" + idModoEnvio;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de los envios del área " + IdArea + ".",
      "Get_cfg_enviosExportExcel", "cfg_envios", url_api);
    return this.http.get<ShipmentExport[]>(url_api, { headers: headersToken });
  }

  getListaEnvioExcelMaritimo(modoEnvio: number): Observable<ShipmentExport[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_envios/consulta/envios/exportToExcel/2" + modoEnvio;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener el tracking de los envios del modo de envio " + modoEnvio + ".",
      "Get_cfg_enviosExportExcel", "cfg_envios", url_api);
    return this.http.get<ShipmentExport[]>(url_api, { headers: headersToken });
  }

  getTipoEnvio(idTipoEnvio: number): Observable<TipoEnvio> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_tipoEnvio/" + idTipoEnvio;
    this.logService.postLogCompleto("GET", "cfg_tipoEnvioController.cs", "Log obtener el tipo envio número " + idTipoEnvio + ".",
      "Get_cfg_tipoenvioAsync", "cfg_tipoEnvio", url_api);
    return this.http.get<TipoEnvio>(url_api, { headers: headersToken });
  }

  getModoEnvio(idModoEnvio: number): Observable<Modoenvio> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_modoEnvio/" + idModoEnvio;
    this.logService.postLogCompleto("GET", "cfg_modoEnvioController.cs", "Log obtener el modo de envio número " + idModoEnvio + ".",
      "Get_cfg_modoenvio", "cfg_modoEnvio", url_api);
    return this.http.get<Modoenvio>(url_api, { headers: headersToken });
  }

  getAeropuertoById(idAeropuerto: number): Observable<Aeropuerto> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "ma_aeropuerto/" + idAeropuerto;
    this.logService.postLogCompleto("GET", "ma_aeropuertoController.cs", "Log obtener el aeropuerto número " + idAeropuerto + ".",
      "Get_ma_aeropuertoByIdAsync", "ma_aeropuerto", url_api);
    return this.http.get<Aeropuerto>(url_api, { headers: headersToken });
  }

  getLocalidadById(idLocalidad: number): Observable<Localidades> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "ma_localidades/" + idLocalidad;
    this.logService.postLogCompleto("GET", "ma_localidadController.cs", "Log obtener la localidad número " + idLocalidad + ".",
      "Get_ma_LocalidadByIdAsync", "ma_localidad", url_api);
    return this.http.get<Localidades>(url_api, { headers: headersToken });
  }

  getPuertoById(idPuerto: number): Observable<Puerto> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "ma_puertos/" + idPuerto;
    this.logService.postLogCompleto("GET", "ma_puertosController.cs", "Log obtener el puerto número " + idPuerto + ".",
      "Getma_puertosById", "ma_puertos", url_api);
    return this.http.get<Puerto>(url_api, { headers: headersToken });
  }

  getEntidad(idEntidad: number): Observable<Entity> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "em_entidad/resumen/" + idEntidad;
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log obtener entidad " + idEntidad + " para el resumen .",
      "Get_em_entidadResumenById", "em_entidad", url_api);
    return this.http.get<Entity>(url_api, { headers: headersToken });
  }

  getTipoDivisa(idTipoDivisa: number): Observable<TipoDivisa> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "cfg_tipodivisa/" + idTipoDivisa;
    this.logService.postLogCompleto("GET", "cfg_tipodivisaController.cs", "Log obtener tipo divisa número " + idTipoDivisa + ".",
      "Get_cfg_tipodivisasById", "cfg_tipodivisa", url_api);
    return this.http.get<TipoDivisa>(url_api, { headers: headersToken });
  }

  getTipoEmbalaje(idTipoEmbalaje: number): Observable<Embalaje> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    let url_api = this.apiUrlService + "ma_tipoembalaje/" + idTipoEmbalaje;
    this.logService.postLogCompleto("GET", "ma_tipoembalajeController.cs", "Log obtener tipo embalaje número " + idTipoEmbalaje + ".",
      "Get_ma_tipoembalajeById", "ma_tipoembalaje", url_api);
    return this.http.get<Embalaje>(url_api, { headers: headersToken });
  }

  compruebaRefCliDuplicados(idReferencia: StringConstructor): Observable<Boolean> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })

    const url_api = this.apiUrlService + "cfg_envios/referencia/" + idReferencia;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener si existe la referencia " + idReferencia + ".",
      "Get_cfg_enviosRevisaDuplicadosReferenciaCliente", "cfg_envios", url_api);
    return this.http.get<Boolean>(url_api, { headers: headersToken.append("Content-Type", "application/json") });
  }

  compruebaRefCliDuplicadosTerrestre(idReferencia: StringConstructor): Observable<Boolean> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "cfg_envios/referencia/terrestre/" + idReferencia;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener si existe la referencia terrestre: " + idReferencia + ".",
      "Get_cfg_enviosRevisaDuplicadosReferenciaClienteTerrestre", "cfg_envios", url_api);
    return this.http.get<Boolean>(url_api, { headers: headersToken.append("Content-Type", "application/json") });
  }

  getDireccionesEntity(entity: Entity): Observable<Direccion[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "em_direcciones/entidad/" + entity.IdEntidad;
    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener las direcciones de la entidad " + entity.IdEntidad + ".",
      "Get_em_direccionesByIdEntidad", "cfg_envios", url_api);
    return this.http.get<Direccion[]>(url_api, { headers: headersToken.append("Content-Type", "application/json") });
  }

  getUrlTracking(partida: string, expedicion: string): Observable<string> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    var url_api = this.apiUrlService + "cfg_envios/";
    if (partida != null && partida != undefined && partida != "")
      url_api += partida + "/tracking/share";
    else
      url_api += expedicion.split('/')[0] + "/tracking/share";

    this.logService.postLogCompleto("GET", "cfg_enviosController.cs", "Log obtener la URL del tracking de la partida " + partida + ".",
      "Get_cfg_envios_trackingPorCompartir", "cfg_envios", url_api);
    return this.http.get<string>(url_api, { headers: headersToken.append("Content-Type", "application/json") });
  }

  getVerificacionTracking(codigoTracking: string): Observable<VerificacionTracking> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });

    const url_api = this.apiUrlService + "cfg_envios/" + codigoTracking + "/tracking/compartir";
    return this.http.get<VerificacionTracking>(url_api, { headers: headersToken.append("Content-Type", "application/json") });
  }

  //fin de clase

  // CORRE AHORA QUE PUEDES CRAAACK
  // SI HAS LEIDO ESTO ESTÁS PERDIDO, LO SIENTO
}