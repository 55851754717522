<div style="display:flex;justify-content:center;align-items:center;">
    <form [formGroup]="formPasswordOlvidada">
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label class="label">{{'Usuario'|translate}}</mat-label>
                    <input class="inputMaterial" [formControl]="usuario" required matInput type="text" placeholder="{{'Usuario'|translate}}">
                    <mat-error *ngIf="usuario.hasError('required')">{{'Tienes que añadir un usuario'|translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="margin-bottom: 2%;">
            <div class="col-sm-12">
                
                <re-captcha (resolved)="resolved($event)"
                    (error)="onError($event)"
                    errorMode="handled"
                    required
                    [formControl]="recaptcha"
                    siteKey="6Lf_9BIaAAAAAEORUVniW_cpPUDSHBVl5R0se-9D"></re-captcha>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <button mat-button type="submit" (click)="olvidaPassword('olvidaPassword');" id="olvidaPassword">{{'Restaurar contraseña'|translate}}</button>
            </div>
        </div>
    </form>
</div>


