import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogModel } from '../models/log-model.model';
import { LogCompleto } from '../models/logCompleto.model';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  ip: string = "";
  localizacion: string = "";
  readonly apiUrlService = environment.apiUrl;

  constructor(private http: HttpClient) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.localizacion = " latitude: " + latitude + " longitude: " + longitude;
      }, (error) => this.localizacion = 'Error al obtener la ubicación: ' + error);
    } else
      this.localizacion = 'Geolocalización no es soportada por este navegador.';

  }

  //Obtenemos el token de la variable local
  getToken() {
    return localStorage.getItem("userToken");
  }

  postLogCompleto(accion: string, controlador: string, descripcion: string,
    metodo: string, tabla: string, url: string) {
    var log: LogModel = new LogModel();
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    var logCompleto = new LogCompleto();
    logCompleto.Accion = accion;
    logCompleto.Controlador = controlador;
    logCompleto.Descripcion = descripcion;
    logCompleto.Metodo = metodo;
    logCompleto.Tabla = tabla;
    logCompleto.Url = url;
    const url_api = this.apiUrlService + "cli_log";
    this.http.post(url_api, {
      "Body": JSON.stringify(logCompleto),
      "Log": log
    }, { headers: headersToken.append("Content-Type", "application/json") })
      .subscribe(data => { })
  }
}
