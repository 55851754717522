import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { RegistroUsuario } from 'src/app/models/registroUsuario.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { cifValidator } from 'src/app/validators/cif-validator.validator';

@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.scss']
})
export class RegistroUsuarioComponent implements OnInit {

  formRegistroUsuario: FormGroup;
  delegaciones = [];
  registerUser: RegistroUsuario;
  clase: string = "app-registro-usuario";
  @Output() cerrarModal = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private translate: TranslateService, private toastr: ToastrService, private authenticationService: AuthenticationService) { 
    this.delegaciones = [{
      idDelegacion: 0,
      delegacion: this.translate.instant("Worldwide")
    },{
      idDelegacion: 1,
      delegacion: this.translate.instant("España")
    },{
      idDelegacion: 2,
      delegacion: this.translate.instant("Mexico")
    },/*{
      idDelegacion: 3,
      delegacion: this.translate.instant("Dubai")
    },*/{
      idDelegacion: 4,
      delegacion: this.translate.instant("Italia")
    }];
  }

  ngOnInit(): void {
    this.formRegistroUsuario = this.formBuilder.group({
      nombreEntidad: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      nombre: ['', Validators.required],
      cif: ['', [Validators.required, cifValidator()]],
      idDelegacion: ['', Validators.required]
    });
  }

  public get nombreEntidad() { return this.formRegistroUsuario.get('nombreEntidad'); }
  public get email() { return this.formRegistroUsuario.get('email'); }
  public get telefono() { return this.formRegistroUsuario.get('telefono'); }
  public get nombre() { return this.formRegistroUsuario.get('nombre'); }
  public get cif() { return this.formRegistroUsuario.get('cif'); }
  public get idDelegacion() { return this.formRegistroUsuario.get('idDelegacion'); }

  convertFormToModel(){
    this.registerUser = new RegistroUsuario();
    this.registerUser.CIF = this.cif.value;
    this.registerUser.Email = this.email.value;
    this.registerUser.IdDelegacion = this.idDelegacion.value;
    this.registerUser.Nombre = this.nombre.value;
    this.registerUser.NombreEntidad = this.nombreEntidad.value;
    this.registerUser.Telefono = this.telefono.value;
  }
  
  registroUsuario(click: string){
    if(this.formRegistroUsuario.valid){
      var log : LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      this.convertFormToModel();
      this.authenticationService.registro(this.registerUser, log).subscribe(d => {
        var body = this.translate.instant("OkBodyRegisterUser");
        var title = this.translate.instant("OkTitleRegisterUser");
        this.toastr.success(body, title);
        this.cerrarModal.emit(true);
      });
    }else{
      var body = this.translate.instant("ErrorBodyRegisterUser");
      var title = this.translate.instant("ErrorTitleRegisterUser");
      this.toastr.error(body, title);
    }
  }

}
