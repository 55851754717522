import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { EnvioAereo } from 'src/app/models/envio-aereo.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Shipment } from 'src/app/models/shipment.model';
import { FileShipService } from 'src/app/services/file-ship.service';
import { Router } from '@angular/router';
import { EnvioMaritimo } from 'src/app/models/envio-maritimo.model';
import { EnvioTerrestre } from 'src/app/models/envio-terrestre.model';
import { Tracking } from 'src/app/models/tracking.model';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { EntityService } from 'src/app/services/entity.service';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shipping-tracking-share',
  templateUrl: './shipping-tracking-share.component.html',
  styleUrls: ['./shipping-tracking-share.component.scss']
})
export class ShippingTrackingShareComponent implements OnInit {
  @Input() codigoTracking: string = "";
  currentDate: Date;
  tracking: Tracking;
  envioAereo: EnvioAereo;
  envioMaritimo: EnvioMaritimo;
  envioTerrestre: EnvioTerrestre;
  envio: Shipment;
  showURL: boolean = false;
  aereo: boolean = false;
  maritimo: boolean = false;
  terrestre: boolean = false;

  constructor(private shipService: ShipmentService, private entityService: EntityService,
    private docsService: FileShipService, private router: Router, private sanitizer: DomSanitizer,
    private modalService: BsModalService) {
     }

  ngOnInit(): void {
    this.envio = this.shipService.envio;
    this.shipService.getTrackingShare(this.codigoTracking).subscribe(data => {
      this.tracking = data;
      this.tracking.UrlSanitizer = this.sanitizer.bypassSecurityTrustResourceUrl(this.tracking.Url);
    },
    error => {
    });
    this.currentDate = new Date();
  }

  tieneIncidencia(){
    return this.tracking.Incidencia;
  }

  getLocations(){
    return this.tracking.LineasTracking.filter(lt => lt.Location != null && lt.Location != undefined && lt.Location != "");
  }

  isPartida(){
    return this.tracking != null && this.tracking != undefined && this.tracking.Partida != null && this.tracking.Partida != undefined;
  }

  isExpedicion(){
    return this.tracking != null && this.tracking != undefined && this.tracking.Expedicion != null && this.tracking.Expedicion != undefined;
  }

  isUndefined(){
    return this.tracking != undefined && this.tracking.LineasTracking != undefined;
  }

  isImport(){
    return this.tracking.Import;
  }
  
  // verDocumentos(shipment: Shipment)
  // {
  //   // POR ANGELO QUE NO SALGA DOCUMENTACIÓN A CLIENTE DE MX EN ESPAÑA.
  //   if(this.entidad.IdCliente == 934 && this.entidad.IdDelegacion == 1){
  //     // No se hace nada
  //   }else{ // Se hace lo de siempre
  //     if(shipment.IdEnvio != null && shipment.IdEnvio != undefined)
  //     {
  //       this.docsService.getDocumentos(shipment.IdEnvio).subscribe(data => {
  //       })
  //     }
  //     else if(shipment.Partida != null && shipment.Partida != undefined) {
  //       this.docsService.getDocumentosPartida(shipment.Partida).subscribe(data => {
  //       })
  //     }
  //     else if(shipment.Expedicion != null && shipment.Expedicion != undefined) {
  //       this.docsService.getDocumentosExpedicion(shipment.Expedicion).subscribe(data => {
  //       })
  //     }
  //     this.router.navigate(['/customer/shipment/documents']);
  //   }
  // }

  listadoDocumentos(shipment: Shipment) : void
  {
    this.shipService.envio = shipment;
    //this.router.navigate(['/customer/shipment/documents']);
  }

  // tslint:disable-next-line:typedef
  downloadPDF() {
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('trackingSpan');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p','pt','a4');
    const options = {
      background: 'white',
      scale: 3
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      docResult.save(this.envio.RefCliente + '_tracking.pdf')
    })
  }

  isViajeRetrasado(linea: Tracking) : boolean{
    if(linea.DiasRetraso > 0)
      return true;
    return false;
  }

  isViajeAdelantado(linea: Tracking) : boolean{
    if(linea.DiasRetraso < 0)
      return true;
    return false;
  }

}
