import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-olvido-password',
  templateUrl: './olvido-password.component.html',
  styleUrls: ['./olvido-password.component.scss']
})
export class OlvidoPasswordComponent implements OnInit {
  public clase: string = "app-olvido-password";
  public formPasswordOlvidada: FormGroup;
  @Output() cerrarModal = new EventEmitter<boolean>()
  public get usuario() { return this.formPasswordOlvidada.get('usuario') }
  public get recaptcha() { return this.formPasswordOlvidada.get('recaptcha') }

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private translateService: TranslateService, private toastr : ToastrService) { }

  ngOnInit(): void {
    this.formPasswordOlvidada = this.formBuilder.group(
      {
      usuario: ['', [Validators.required]],
      recaptcha: [null, Validators.required]
    });
  }

  public resolved(captchaResponse: string): void {
    this.formPasswordOlvidada.value.recaptcha = captchaResponse; 
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.formPasswordOlvidada.value.recaptcha = null; 
  }

  olvidaPassword(click: string){
    var log :LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    if(this.formPasswordOlvidada.valid){
      this.authenticationService.olvidoPassword(log, this.usuario.value.toString()).subscribe(data => {
        var Body = this.translateService.instant("Se ha restaurado la contraseña con éxito y se ha enviado un correo electrónico al usuario.");
        var Titulo = this.translateService.instant("Contraseña restaurada con éxito");
        this.toastr.success(Body, Titulo);
        this.cerrarModal.emit(true);
      }, error => {
        var status: string = error.status;
        if(status == "404"){ // Error no ha encontrado el usuario
          var Body = this.translateService.instant("No se encuentra el usuario.");
          var Titulo = this.translateService.instant("Error");
          this.toastr.error(Body, Titulo);
        }else if(status == "415"){ // Error no tiene email
          var Body = this.translateService.instant("Este usuario no tiene asociado un correo electrónico, pongáse en contacto con la empresa.");
          var Titulo = this.translateService.instant("Error");
          this.toastr.error(Body, Titulo);
        }else{  // Error catch
          var Body = this.translateService.instant("Contacte con nuestra empresa para darte soporte.");
          var Titulo = this.translateService.instant("Error");
          this.toastr.error(Body, Titulo);
        }
      });
    }
  }

}
