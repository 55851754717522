import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActualizarFacturaComponent } from '../actualizar-factura/actualizar-factura.component';
import { AvisoSeguroComponent } from '../aviso-seguro/aviso-seguro.component';
import { AvisoSiNoComponent } from '../aviso-si-no/aviso-si-no.component';
import { ConfirmacionPlantillaComponent } from '../confirmacion-plantilla/confirmacion-plantilla.component';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { UploadModalComponent } from '../upload-modal/upload-modal.component';
import { SharedModule } from 'src/app/shared/shared/shared.module';
import { FormularioAddresBookModule } from '../../users/ajustes/address-book/formulario-address-book/formulario-addres-book/formulario-addres-book.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotFound404Component } from '../../errors/not-found404/not-found404.component';
import { GaleriaImagenesComponent } from '../../users/conductor/incidencias/galeria-imagenes/galeria-imagenes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { MapaService } from 'src/app/services/mapa.service';
import { MapaComponent } from '../mapa/mapa.component';
import { DndDirectiveDirective } from '../dnd-directive.directive';



@NgModule({
  declarations: [
    ActualizarFacturaComponent,
    AvisoSeguroComponent,
    AvisoSiNoComponent,
    ConfirmacionPlantillaComponent,
    UploadFileComponent,
    UploadModalComponent,
    NotFound404Component,
    GaleriaImagenesComponent,
    DndDirectiveDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    AngularMaterialModule,
    NgxPicaModule,
    Ng2SearchPipeModule,
    SharedModule,
    FormularioAddresBookModule,
  ],
  exports: [
    ActualizarFacturaComponent,
    AvisoSeguroComponent,
    AvisoSiNoComponent,
    ConfirmacionPlantillaComponent,
    UploadFileComponent,
    UploadModalComponent,
    DndDirectiveDirective,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  providers: [MapaService]
})
export class ComponentesAuxiliaresModule { }
