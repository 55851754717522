import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { User } from "../models/user.model";
// Para poner la url de la API sin tener que cambiar todos
import { environment } from '../../environments/environment';
import { Conductor } from '../models/conductor.model';
import { LogModel } from '../models/log-model.model';
import { CambiarPassword } from '../models/cambiarPassword.model';
import { LogService } from './log.service';
import { RegistroUsuario } from '../models/registroUsuario.model';
import { Archivo } from '../models/archivo.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  readonly apiUrlService = environment.apiUrl;
  ip: string = "";
  localizacion: string = "";

  constructor(private http: HttpClient, private logService: LogService) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.localizacion = " latitude: " + latitude + " longitude: " + longitude;
      }, (error) => this.localizacion = 'Error al obtener la ubicación: ' + error);
    } else
      this.localizacion = 'Geolocalización no es soportada por este navegador.';
  }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  // No se pone aquí por que como todavia no ha cargado el Token, será null y no se podrá hacer nada con el Token
  /*headersToken: HttpHeaders = new HttpHeaders({
    "Authorization": "Bearer " + this.getToken()
  })*/



  //Peticion que se realiza para comprobar si hay o no un usuario con los parametros pasados
  /*loginUser(Usuario: string, Contrasenya: string): Observable<User>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "web_usuarios";
    return this.http.post(url_api,JSON.stringify({Usuario, Contrasenya}), {headers: headersToken}).pipe(map(data => data));
  }*/

  olvidoPassword(log: LogModel, usuario: string) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken(),
      "Content-Type": "application/json"
    })
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "web_usuarios/olvidoPassword";
    return this.http.post(url_api, JSON.stringify({ "Log": log, "Body": JSON.stringify({ "Usuario": usuario }) }), { headers: headersToken }).pipe(map(data => data));
  }

  /*getLogo()
  {
    //añadir en la bbdd en el diario de conexiones la conexion
    const url_api = this.apiUrlService + "web_usuarios/logo";
    return this.http.get(url_api, {headers: this.headers}).pipe(map(data => data));
  }*/

  registro(registerUser: RegistroUsuario, log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken(),
      "Content-Type": "application/json"
    })
    const url_api = this.apiUrlService + "web_usuarios/registro";
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    return this.http.post(url_api, JSON.stringify({ "Log": log, "Body": JSON.stringify(registerUser).toString() }),
      { headers: headersToken }).pipe(map(data => data));
  }

  login(Usuario: string, Contrasenya: string, log: LogModel) {
    var usuario = { Usuario, Contrasenya }
    const url_api = this.apiUrlService + "web_usuarios/login";
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    return this.http.post(url_api, JSON.stringify({ "Log": log, "Body": JSON.stringify(usuario).toString() }),
      { headers: this.headers }).pipe(map(data => data));
  }

  public getLogo() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "web_usuarios/logo";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log del get de obtención del logo.", "Get_Logo_usuarioLogin", "web_usuarios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data));
  }

  logOut(log: LogModel) {

    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "web_usuarios/logout";
    return this.http.post(url_api, { "Log": log }, { headers: headersToken }).pipe(map(data => data));
  }

  limpiarLocalStorage(): void {
    localStorage.removeItem("userToken");
    localStorage.removeItem("nombre");
  }

  setToken(token): void {
    localStorage.setItem("userToken", token);
  }

  setNombre(nombre) {
    localStorage.setItem("nombre", nombre);
  }

  getNombre() {
    return localStorage.getItem("nombre");
  }

  //Obtenemos el token de la variable local
  getToken() {
    return localStorage.getItem("userToken");
  }

  //Seteamos el tipo de usuario que es en una variable local -- Hay que probar si no es necesario hacerlo, 
  //porque entonces una persona de forma local podría entrar a una parte del  programa cambiando el tipo de usuario
  setTipoUser(user): void {
    localStorage.setItem("tipoUser", user);
  }

  //Obtenemos el tipo de usuario de variable local
  getTipoUser() {
    return localStorage.getItem("tipoUser");
  }

  //Funcion que cierra la sesion y elimina los tokens
  logoutUser()/*: Observable<any>*/ {
    /*var token = this.getToken();
    const url_api = this.apiUrlService + "logout?token=" + token;
    return this.http.put(url_api, {headers: this.headers}).pipe(map(data => data));*/
  }

  getTipoUsuario(): Observable<any> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "web_usuarios/tipoUser";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log del tipo usuario.", "GetTipoUser_web_usuarios", "web_usuarios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data));
  }

  cambioPassword(cambiarPassword: CambiarPassword, log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: log,
      Body: JSON.stringify(cambiarPassword).toString()
    })
    const url_api = this.apiUrlService + "web_usuarios/cambiarPassword";
    return this.http.post(url_api, json, { headers: headersToken }).pipe(map(data => data));
  }

  esPrimeraVez() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "web_usuarios/esPrimeraVez";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log del tipo usuario.", "Get_PrimeraVez", "web_usuarios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data));
  }

  esCaducada() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "web_usuarios/esCaducado";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log del tipo usuario.", "Get_EsCaducada", "web_usuarios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data));
  }

  getPrivilegiosUsuario() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "web_usuarios/privilegios";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log de los privilegios.", "Get_privilegios", "web_usuarios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as string[]));
  }

  getPrivilegio(privilegio: string) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "web_privilegios/" + privilegio;
    this.logService.postLogCompleto("GET", "web_privilegioController.cs", "Log del privilegio " + privilegio + ".", "Get_privilegio", "web_privilegios", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as number));
  }

}
