<div class="container" *ngIf="tracking != undefined && tracking != null">
  <div class="row">
    <div class="col-sm-12" id="tracking">
      <span id="trackingSpan">
        <div class="block">
          <h1>TRACK & TRACE</h1>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive-sm scrollable-element">
              <table
                id="primeraTabla"
                class="table table-sm table-bordered table-hover table-editable table-row-action edit-action"
              >
                <thead>
                  <th>{{ "Ref. Cliente" | translate }}</th>
                  <th>{{ "Remitente" | translate }}</th>
                  <th>{{ "Destinatario" | translate }}</th>
                  <th>{{ "Bultos" | translate }}</th>
                  <th>{{ "P.Bruto" | translate }}</th>
                  <th>{{ "Volumen" | translate }}</th>
                  <th>{{ "Estado" | translate }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ tracking.ReferenciaCliente }}</td>
                    <td>{{ tracking.Remitente }}</td>
                    <td>{{ tracking.Destinatario }}</td>
                    <td>{{ tracking.Bultos }}</td>
                    <td>{{ tracking.PesoBruto }}</td>
                    <td>{{ tracking.Volumen }}</td>
                    <td>{{ tracking.Status | translate }}</td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-sm table-bordered table-hover table-editable table-row-action edit-action"
              >
                <thead>
                  <th *ngIf="isPartida()">{{ "Partida" | translate }}</th>
                  <th *ngIf="isExpedicion()">{{ "Expedicion" | translate }}</th>
                  <th *ngIf="isPartida()">Ref/AWB/MBL</th>
                  <th>{{ "Origen" | translate }}</th>
                  <th>{{ "Destino" | translate }}</th>
                  <th>ETD</th>
                  <th>ETA</th>
                  <th *ngIf="!isPartida()">
                    {{ "Transportista" | translate }}
                  </th>
                  <th *ngIf="isPartida() && isImport()">
                    {{ "Agente en origen" | translate }}
                  </th>
                  <th *ngIf="isPartida() && !isImport()">
                    {{ "Agente de destino" | translate }}
                  </th>
                  <!-- <th *ngIf="tieneIncidencia()">{{'Incidencia'|translate}}</th> -->
                </thead>
                <tbody>
                  <td *ngIf="isPartida()">{{ tracking.Partida }}</td>
                  <td *ngIf="isExpedicion()">{{ tracking.Expedicion }}</td>
                  <td *ngIf="isPartida()">{{ tracking.RefAwbMbl }}</td>
                  <td>{{ tracking.Origen }}</td>
                  <td>{{ tracking.Destino }}</td>
                  <td>{{ tracking.ETD }}</td>
                  <td>{{ tracking.ETA }}</td>
                  <td *ngIf="!isPartida()">{{ tracking.Carrier }}</td>
                  <td *ngIf="isPartida()">{{ tracking.DeliveryAgent }}</td>
                </tbody>
              </table>
            </div>
          </div>
        </div></span
      >
    </div>
  </div>
</div>
