<div class="fondoPantalla view">
    <app-header-no-login></app-header-no-login>
    <div class="container-fluid mask" style="width: 100%; margin: auto;">
        <div class="row" style="margin-top: 10%;">
            <!--<div class="col-12 col-sm-6" style="text-align: center;">
                <div class="row">
                    <div class="col-12 col-sm-6"></div>
                    <div class="col-12 col-sm-5">
                        <h3>Ya tienes cuenta</h3>
                    </div>
                    <div class="col-12 col-sm-1"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6" style="text-align: center;">
                <div class="row">
                    <div class="col-12 col-sm-1"></div>
                    <div class="col-12 col-sm-5">
                        <h3>¿Todavía no tienes cuenta?</h3>
                    </div>
                    <div class="col-12 col-sm-6"></div>
                </div>
            </div>-->
        </div>
        <div class="row h-100" >
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-12 col-sm-6"></div>
                    <div class="col-12 col-sm-5">
                        <div class="centerText marginBot">
                            <h3>{{'TienesCuentaTitle' | translate}}</h3>
                        </div>
                        <div class="contenedor">
                            <h5>{{"InciarSesionTitle" | translate }}</h5>
                            <form #formLogin="ngForm" (ngSubmit)="logeado('InicioSesion')">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width">
                                        <mat-label class="label" style="color:black;">{{'Usuario'|translate}}</mat-label>
                                        <input matInput  autocomplete="off" #User="ngModel" type="text" class="inputMaterial sizeLogin"
                                        id="User" name="User" [(ngModel)]="formModel.UserName" (focusout)="compruebaUsuario()" required>
                                        <mat-error *ngIf="!User.valid || User.untouched">
                                            {{'Usuario obligatorio'|translate}}
                                        </mat-error>
                                    </mat-form-field>
                                    
                                </div>
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" >
                                        <mat-label class="label" style="color:black;">{{'Contraseña'|translate}}</mat-label>
                                        <input matInput autocomplete="off" #Password="ngModel" type="{{tipoPassword}}" class="inputMaterial"
                                        id="Password" name="Password" [(ngModel)]="formModel.Password" (focusout)="compruebaPassword()" required>
                                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: white;" aria-label="ChangePassword" (click)="changePassword();">
                                            <mat-icon *ngIf="tipoPassword == 'password'">remove_red_eye</mat-icon>
                                            <mat-icon *ngIf="tipoPassword == 'text'">visibility_off</mat-icon>
                                        </button>
                                        <mat-error *ngIf="!Password.valid || Password.untouched">
                                            {{'Contraseña obligatoria'|translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <p (click)="openModal(templatePaswordOlvidada)">{{'¿Has olvidado la contraseña?'| translate}}</p>
                                </div>
                                <!--<div class="form-group">
                                    <p (click)="openModal(templateUsuarioRegistro)">{{'RegistroUsuarioLogin'| translate}}</p>
                                </div>-->
                                <div class="row">
                                    <div class="col-12 col-sm-12 form-group ">
                                        <button mat-button type="submit" id="InicioSesion" class="btn">{{'Acceso'|translate}}</button>
                                    </div>
                                </div>
                                <!--<div class="form-group" style="text-align: center;">
                                    <button type="submit" id="InicioSesion" class="btn">{{'Acceso'|translate}}</button>
                                </div>-->
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-sm-1"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 ">
                <div class="row">
                    <div class="col-12 col-sm-1"></div>
                    <div class="col-12 col-sm-5">
                        <div class="centerText marginBot">
                            <h3>{{"NoTienesCuentaTitle" | translate}}</h3>
                        </div>
                        <div class="contenedor">
                            <h5>{{"RegistroTitle" | translate}}</h5>
                            <form [formGroup]="formRegistroUsuario">
                                <div class="row">
                                    <div class="col-12 col-sm-12 form-group ">
                                        <mat-form-field class="example-full-width" >
                                            <mat-label class="label">{{'Entidad'|translate}}</mat-label>
                                            <input class="inputMaterial" [formControl]="nombreEntidad" required matInput type="text" placeholder="{{'Entidad'|translate}}">
                                            <mat-error *ngIf="nombreEntidad.hasError('required')">{{'EntidadRequired'|translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-12 form-group ">
                                        <mat-form-field class="example-full-width" >
                                            <mat-label class="label">{{'Email'|translate}}</mat-label>
                                            <input class="inputMaterial" [formControl]="email" required matInput type="text" placeholder="{{'Email'|translate}}">
                                            <mat-error *ngIf="email.hasError('required')">{{'EmailRequired'|translate}}</mat-error>
                                            <mat-error *ngIf="email.hasError('email')">{{'FormatEmailError'|translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 form-group ">
                                        <mat-form-field class="example-full-width">
                                            <mat-label class="label">{{'Telefono'|translate}}</mat-label>
                                            <input class="inputMaterial" [formControl]="telefono" required matInput type="text" placeholder="{{'Telefono'|translate}}">
                                            <mat-error *ngIf="telefono.hasError('required')">{{'TelefonoRequired'|translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-12 form-group ">
                                        <mat-form-field class="example-full-width" >
                                            <mat-label class="label">{{'Nombre'|translate}}</mat-label>
                                            <input class="inputMaterial" [formControl]="nombre" required matInput type="text" placeholder="{{'Nombre'|translate}}">
                                            <mat-error *ngIf="nombre.hasError('required')">{{'NombreRequired'|translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 form-group ">
                                        <mat-form-field class="example-full-width" >
                                            <mat-label class="label">{{'CIF'|translate}}</mat-label>
                                            <input class="inputMaterial" [formControl]="cif" required matInput type="text" placeholder="{{'CIF'|translate}}">
                                            <mat-error *ngIf="cif.hasError('required')">{{'CifRequired'|translate}}</mat-error>
                                            <mat-error *ngIf="!cif.hasError('required') && cif.hasError('cifValidator')">{{'CifFormatoError'|translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-12 form-group">
                                        <mat-form-field appearance="fill" class="example-full-width" style="margin-left: -14%;">
                                            <mat-label class="label">{{'DelegacionLabel'|translate}}</mat-label>
                                            <mat-select [formControl]="idDelegacion">
                                                <mat-option *ngFor="let del of delegaciones" [value]="del.idDelegacion">
                                                {{del.delegacion}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="idDelegacion.hasError('required')">{{'DelegacionRequired'|translate}}</mat-error>
                                            </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 form-group">
                                        <button mat-button type="submit" (click)="registroUsuario('registroUsuario');" id="registroUsuario">{{'RegistroUsuario'|translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6"></div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Template Password Olvidada -->
<ng-template #templatePaswordOlvidada>
    <div class="modal-header fondo" style="border-color: black;">
    <p class="h4 modal-title pull-left">{{'¿Has olvidado la contraseña?'|translate}}</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body fondo">
        <app-olvido-password (cerrarModal)="cerrarModal($event)"></app-olvido-password>
    </div>
</ng-template>

<!-- Template Registro Usuario -->
<ng-template #templateUsuarioRegistro>
    <div class="modal-header fondo" style="border-color: black;">
    <p class="h4 modal-title pull-left">{{'RegistroUsuarioTitle'|translate}}</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body fondo">
        <app-registro-usuario (cerrarModal)="cerrarModal($event)"></app-registro-usuario>
    </div>
</ng-template>