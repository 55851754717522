import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MapaService {

  readonly apiUrlService = environment.apiUrl;

  public mapa: google.maps.Map;
  public existMarker: boolean;

  textoLocalidadOrigen$: Subject<string> = new Subject();
  textoLocalidadDestino$: Subject<string> = new Subject();

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getTextoLocalidadOrigen$(): Observable<string>{
    return this.textoLocalidadOrigen$.asObservable();
  }

  setTextoLocalidadOrigen$(textoLocalidadOrigen: string){
    this.textoLocalidadOrigen$.next(textoLocalidadOrigen);
  }

  getTextoLocalidadDestino$(): Observable<string>{
    return this.textoLocalidadDestino$.asObservable();
  }

  setTextoLocalidadDestino$(textoLocalidadDestino: string){
    this.textoLocalidadDestino$.next(textoLocalidadDestino);
  }
}
