import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Shipment } from 'src/app/models/shipment.model';
import { VerificacionTracking } from 'src/app/models/verificacionTracking.model';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss']
})
export class TrackingDetailsComponent implements OnInit {

  verificacionTracking: VerificacionTracking;
  codigoTracking: string = "";

  constructor(private route: ActivatedRoute, private shipmentService: ShipmentService) { }

  ngOnInit(): void {
    this.codigoTracking = this.route.snapshot.paramMap.get('codigoTracking');
    this.shipmentService.getVerificacionTracking(this.codigoTracking).subscribe(data => {
      this.verificacionTracking = data;
      this.shipmentService.envio = new Shipment();
      if(this.verificacionTracking.PartidaExpedicion.startsWith('P'))
        this.shipmentService.envio.Partida = data.PartidaExpedicion;
      else
        this.shipmentService.envio.Expedicion = data.PartidaExpedicion;
    });
  }

}
