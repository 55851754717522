import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProveedorGuard implements CanActivate {

  constructor(public router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService, private toast: ToastrService){}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
        this.authenticationService.getTipoUsuario().subscribe(data => {
          if(data && data == "10"){
            resolve(true);
          }else{
            var Body = this.translateService.instant("No tienes permisos para entrar. Te has equivocado de login.");
            var Titulo = this.translateService.instant("Error");
            this.toast.error(Body, Titulo)
            this.authenticationService.limpiarLocalStorage();
            this.router.navigate(['login']);
            reject(false);
          }
        },() => {
          var Body = this.translateService.instant("Ha caducado la sesión, tienes que volver a iniciar sesión.");
          var Titulo = this.translateService.instant("Error");
          this.toast.error(Body, Titulo)
          this.authenticationService.limpiarLocalStorage();
          this.router.navigate(['login']);
          reject(false);
        })
    })
  }
  
}
