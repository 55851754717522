import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { User } from "../models/user.model";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  readonly apiUrlService = environment.apiUrl;

  constructor(private http: HttpClient) { }

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type" : "application/json"
  });

  login(Usuario: string, Contrasenya: string): Observable<User>
  {
    //añadir en la bbdd en el diario de conexiones la conexion
    const url_api = "https://localhost:44317/api/" + "web_usuarios";
    return this.http.post(url_api, JSON.stringify({Usuario, Contrasenya}), {headers: this.headers}).pipe(map(data => data));
  }

  

  /*logout(): Observable<any>
  {
    var token = this.getToken();
    const url_api = this.apiUrlService + "logout";
    return this.http.put(url_api, { headers: this.headers }).pipe(map(data => data));
  }*/

  //GETTER Y SETTER TOKEN
  getEntidad() {
    return localStorage.getItem("Entidad");
  }

  setEntidad(entidad): void {
    localStorage.setItem("Entidad", entidad)
  }

  getToken()
  {
    return localStorage.getItem("token");
  }

  setToken(token): void
  {
    localStorage.setItem("token", token);
  }

  //GETTER Y SETTER NOMBRE USUARIO
  getUser()
  {
    return localStorage.getItem("nombre");
  }

  setNomUser(nomUser): void
  {
    localStorage.setItem("nombre", nomUser);
  }

  //GETTER Y SETTER TIPO USUARIO
  setTipoUser(tipo): void{
    localStorage.setItem("tipo", tipo);
  }

  getTipoUser(){
    return localStorage.getItem("tipo");
  }
}
