<form [formGroup]="formRegistroUsuario">
    <div class="row">
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field>
                <mat-label class="label">{{'Entidad'|translate}}</mat-label>
                <input class="inputMaterial" [formControl]="nombreEntidad" required matInput type="text" placeholder="{{'Entidad'|translate}}">
                <mat-error *ngIf="nombreEntidad.hasError('required')">{{'EntidadRequired'|translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field>
                <mat-label class="label">{{'Email'|translate}}</mat-label>
                <input class="inputMaterial" [formControl]="email" required matInput type="text" placeholder="{{'Email'|translate}}">
                <mat-error *ngIf="email.hasError('required')">{{'EmailRequired'|translate}}</mat-error>
                <mat-error *ngIf="email.hasError('email')">{{'FormatEmailError'|translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field>
                <mat-label class="label">{{'Telefono'|translate}}</mat-label>
                <input class="inputMaterial" [formControl]="telefono" required matInput type="text" placeholder="{{'Telefono'|translate}}">
                <mat-error *ngIf="telefono.hasError('required')">{{'TelefonoRequired'|translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field>
                <mat-label class="label">{{'Nombre'|translate}}</mat-label>
                <input class="inputMaterial" [formControl]="nombre" required matInput type="text" placeholder="{{'Nombre'|translate}}">
                <mat-error *ngIf="nombre.hasError('required')">{{'NombreRequired'|translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field>
                <mat-label class="label">{{'CIF'|translate}}</mat-label>
                <input class="inputMaterial" [formControl]="cif" required matInput type="text" placeholder="{{'CIF'|translate}}">
                <mat-error *ngIf="cif.hasError('required')">{{'CifRequired'|translate}}</mat-error>
                <mat-error *ngIf="!cif.hasError('required') && cif.hasError('cifValidator')">{{'CifFormatoError'|translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 ml-auto">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label class="label">{{'DelegacionLabel'|translate}}</mat-label>
                <mat-select [formControl]="idDelegacion">
                  <mat-option *ngFor="let del of delegaciones" [value]="del.idDelegacion">
                    {{del.delegacion}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="idDelegacion.hasError('required')">{{'DelegacionRequired'|translate}}</mat-error>
              </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 ml-auto">
            <button mat-button type="submit" (click)="registroUsuario('registroUsuario');" id="registroUsuario">{{'RegistroUsuario'|translate}}</button>
        </div>
    </div>
</form>