import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormularioAddressBookComponent } from '../formulario-address-book.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { MapaComponent } from 'src/app/components/componentesAuxiliares/mapa/mapa.component';



@NgModule({
  declarations: [
    FormularioAddressBookComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild(),
  ],
  exports: [
    FormularioAddressBookComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class FormularioAddresBookModule { }
