import { AbstractControl, ValidatorFn } from "@angular/forms";

export function nifValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let letrasNif: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
        let nif: string = control.value;
        if(nif == null)
            return {'nifValidator': true}
        let letra: string = nif.substr(8, 1).toUpperCase();
        let numNif: number = Number(nif.substr(0, 8));
        let resto = numNif % 23;
        if(letrasNif[resto] != letra){
            return {'nifValidator': true}
        }
        return null;
    };
}